import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Box, Grid, Stack } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, PhoenixLink, StatusBadge } from 'componix'
import { UUID } from 'crypto'
import UnitStatDetailsModel from '../../../models/UnitStatDetailsModel'

interface UnitStatInfoGutterCardProps {
  unitStat: UnitStatDetailsModel | undefined
  uuid: UUID | null | undefined
  isLoading: boolean
}

const UnitStatInfoGutterCard = ({ unitStat, uuid, isLoading }: UnitStatInfoGutterCardProps) => {
  if (!unitStat) return <></>
  return (
    <PhoenixBaseCard cardTitle="Unit Stat Information">
      <Stack sx={{ margin: '16px' }} data-testid="loaded" spacing={1}>
        <Box width={'fit-content'}>
          <StatusBadge description={unitStat?.status} isLoading={isLoading} />
        </Box>
        <Grid container columnGap={5}>
          <Grid item>
            <FieldGroup
              label="Report Level"
              value={<PhoenixLink to={`/unitstats/${uuid}`} value={unitStat?.reportLevel ?? ''} />}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item>
            <FieldGroup label="Correction Seq" value={unitStat?.correctionSequence} isLoading={isLoading} />
          </Grid>
        </Grid>
        <FieldGroup label="Correction Type" value={unitStat?.correctionType} isLoading={isLoading} />
        <FieldGroup
          label="Accepted Date"
          value={unitStat?.acceptedDate}
          icon={<CalendarTodayIcon />}
          isLoading={isLoading}
        />
        <FieldGroup
          label="Received Date"
          value={unitStat?.receivedDate}
          icon={<CalendarTodayIcon />}
          isLoading={isLoading}
        />
      </Stack>
    </PhoenixBaseCard>
  )
}

export default UnitStatInfoGutterCard
