import { Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { ErrorTrackingTable, ErrorTrackingTableColumns, FieldGroup, NoResultsFound, PhoenixBaseCard } from 'componix'
import { useGetUnitStatErrors, useUpdateUnitStatErrors } from '../../cache/unitStatCache'
import useAttachmentsModal from '../../hooks/useAttachmentsModal'
import useErrorProcessingModal from '../../hooks/useErrorProcessingModal'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { UpdateErrorsModel } from '../../models/PolicyDetails/UpdateErrorsModel'
import AcceptErrorModal from '../Modals/AcceptErrorModal'
import AttachmentsModal from '../Modals/AttachmentsModal'
import RejectErrorModal from '../Modals/RejectErrorModal'

interface UnitStatReportsErrorsProps {
  uuid: string
}

const UnitStatReportsErrors = ({ uuid }: UnitStatReportsErrorsProps) => {
  const queryClient = useQueryClient()

  const {
    data: errorData,
    isLoading: isErrorDataLoading,
    isError: isErrorDataError,
    refetch: refetchErrorData,
  } = useGetUnitStatErrors(uuid)

  const { mutateAsync } = useUpdateUnitStatErrors(uuid, queryClient)

  const updateErrors = async (request: UpdateErrorsModel) => {
    return await mutateAsync(request)
  }

  const {
    isModalOpen: isAcceptModalOpen,
    openModal: openAcceptModal,
    closeModal: closeAcceptModal,
    acceptErrors,
  } = useErrorProcessingModal(updateErrors)

  const {
    isModalOpen: isRejectModalOpen,
    openModal: openRejectModal,
    closeModal: closeRejectModal,
    rejectErrors,
  } = useErrorProcessingModal(updateErrors)

  const {
    isModalOpen: isAttachmentsModalOpen,
    openModal: openAttachmentsModal,
    closeModal: closeAttachmentsModal,
    currentAttachments: attachments,
  } = useAttachmentsModal()

  useSetAlertDetails([isErrorDataError])

  return (
    <PhoenixBaseCard
      cardTitle="USR Errors"
      onExpand={refetchErrorData}
      collapsible={true}
      isLoading={isErrorDataLoading}
    >
      {errorData ? (
        <>
          <Grid container padding={'8px 16px'}>
            <Grid item xs={3}>
              <FieldGroup label="Notice Date" value={errorData?.noticeDate}></FieldGroup>
            </Grid>
            <Grid item xs={3}>
              <FieldGroup label="Fine Due Date" value={errorData?.fineDueDate}></FieldGroup>
            </Grid>
            <Grid item xs={3}>
              <FieldGroup label="Fine Status" value={errorData?.fineStatus}></FieldGroup>
            </Grid>
            <Grid item xs={3}>
              <FieldGroup label="Fine Status Date" value={errorData?.fineStatusDate}></FieldGroup>
            </Grid>
          </Grid>
          <ErrorTrackingTable
            data={errorData.errors}
            acceptFunc={openAcceptModal}
            rejectFunc={openRejectModal}
            attachmentsFunc={openAttachmentsModal}
            columns={ErrorTrackingTableColumns}
          />
          <AcceptErrorModal
            isOpen={isAcceptModalOpen}
            onCancel={closeAcceptModal}
            onAccept={() => acceptErrors(uuid, 'unitstat')}
          />
          <RejectErrorModal
            isOpen={isRejectModalOpen}
            onCancel={closeRejectModal}
            onReject={(responseText: string) => rejectErrors(uuid, 'unitstat', responseText)}
          />
          <AttachmentsModal
            isOpen={isAttachmentsModalOpen}
            onCancel={closeAttachmentsModal}
            attachments={attachments}
          />
        </>
      ) : (
        <NoResultsFound />
      )}
    </PhoenixBaseCard>
  )
}

export default UnitStatReportsErrors
