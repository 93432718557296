import { CalendarToday, DescriptionOutlined, Person } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { FieldGroup, PhoenixBaseCard, StatusBadge } from 'componix'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWorkItemsManualClose, useWorkItemsReassignUser } from '../../cache/workQueueCache'
import { useAlertContext } from '../../contexts/AlertContext'
import { useBoolean } from '../../hooks/useBoolean'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import WorkItemDetailsModel from '../../models/WorkItemDetails/WorkItemDetailsModel'
import { isWorkItemTypePT1 } from '../../utils/isWorkItemTypePT1'
import { AlertSeverity } from '../AlertNotification/AlertNotification'
import ManualCloseModal from '../Modals/ManualCloseModal'
import WorkItemReassignModal from '../Modals/WorkItemReassignModal'

interface WorkItemDetailsCardProps {
  data?: WorkItemDetailsModel | null
  isLoading: boolean
}

//================================================================================================
export const WorkItemDetailsCard = ({ isLoading, data }: WorkItemDetailsCardProps) => {
  const { setDetails: setAlertDetails } = useAlertContext()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const {
    value: isManualCloseModalOpen,
    onTrue: openManualCloseModal,
    onFalse: closeManualCloseModal,
  } = useBoolean(false)
  const { value: isReassignModalOpen, onTrue: openReassignModal, onFalse: closeReassignModal } = useBoolean(false)

  const {
    mutate: handleManualClose,
    isError: isManualCloseError,
    isSuccess: isManualCloseSuccess,
  } = useWorkItemsManualClose([data?.workQueue_WorkItemID as string], queryClient)

  const {
    mutate: handleReassign,
    isError: isReassignUserError,
    isSuccess: isReassignUserSuccess,
  } = useWorkItemsReassignUser([data?.workQueue_WorkItemID as string], queryClient)

  useEffect(() => {
    if (isManualCloseSuccess) {
      setAlertDetails({ message: 'Work Item closed successfully.', severity: AlertSeverity.SUCCESS })
      navigate('/workqueue', { replace: true })
    } else if (isReassignUserSuccess) {
      setAlertDetails({ message: 'Work Item reassigned successfully.', severity: AlertSeverity.SUCCESS })
      navigate('/workqueue', { replace: true })
    }
    //eslint-disable-next-line
  }, [isManualCloseSuccess, isReassignUserSuccess])

  useSetAlertDetails([isManualCloseError], 'Work Item cannot be closed. Please try again.')
  useSetAlertDetails([isReassignUserError], 'Work Item Failed To Reassign.')

  //================================================================================================
  const kebabMenuItems = [
    { label: 'Reassign', to: '#', onClick: openReassignModal },
    {
      label: 'Manually Close',
      disabled: isWorkItemTypePT1(data?.workItemType),
      to: '#',
      onClick: openManualCloseModal,
    },
  ]

  return (
    <>
      <PhoenixBaseCard
        variantType="Primary"
        cardTitle="Work Item Details"
        contentPadded
        defaultWidth={12}
        kebabable={!isLoading && data?.workItemStatus === 'Open'}
        kebabMenuItems={kebabMenuItems}
      >
        <Stack sx={{ margin: '16px' }}>
          <Box width={'fit-content'} marginBottom={'8px'}>
            <StatusBadge description={data?.workItemStatus} isLoading={isLoading} />
          </Box>
          <FieldGroup
            label={'Work Item Number'}
            icon={<DescriptionOutlined />}
            value={data?.workItemNumber}
            isLoading={isLoading}
          />
          <FieldGroup icon={<Person />} label={'Assigned To'} value={data?.assignedTo} isLoading={isLoading} />
          <FieldGroup icon={<Person />} label={'Assigned By'} value={data?.assignedBy} isLoading={isLoading} />
          <FieldGroup
            label={'Assigned Date'}
            icon={<CalendarToday />}
            value={data?.assignedDate}
            isLoading={isLoading}
          />
          <FieldGroup
            label={'Due Date'}
            icon={<CalendarToday />}
            value={
              data?.dueDate && <Typography {...(data?.isPastDue && { color: 'error' })}>{data?.dueDate}</Typography>
            }
            isLoading={isLoading}
          />
        </Stack>
      </PhoenixBaseCard>
      <ManualCloseModal
        isOpen={isManualCloseModalOpen}
        onCancel={closeManualCloseModal}
        onManualClose={(responseText: string) => handleManualClose(responseText)}
      />

      <WorkItemReassignModal
        isOpen={isReassignModalOpen}
        onCancel={closeReassignModal}
        onReassign={(userEmail: string) => handleReassign(userEmail)}
      />
    </>
  )
}

export default WorkItemDetailsCard
