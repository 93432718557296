import { PhoenixBaseCard, PhoenixBaseTable, PhoenixBaseTableProps, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useBoolean } from '../../hooks/useBoolean'
import { CombinedCoverageHistoryModel } from '../../models/CombinedCoverageHistory'
import { handleTitleCase } from '../../utils/handleTitleCase'
import { MoveCoverageModal, SourceCoverageData } from '../Modals/MoveCoverage/MoveCoverageModal'

const columns: MRT_ColumnDef<CombinedCoverageHistoryModel>[] = [
  {
    accessorKey: 'coverageId',
    header: 'COVERAGE ID',
    maxSize: 70,
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) => <PhoenixLink to={`/coverages/${cell.getValue<string>()}`} value={cell.getValue<string>()} />,
  },
  {
    accessorKey: 'primaryName',
    minSize: 200,
    header: 'CURRENT PRIMARY NAME',
    Cell: ({ cell }) => handleTitleCase(cell.getValue<string>()),
  },
  { accessorKey: 'includeDate', header: 'INCLUDE DATE' },
  { accessorKey: 'excludeDate', header: 'EXCLUDE DATE' },
  { accessorKey: 'controllingStartDate', minSize: 120, header: 'CONTROLLING START DATE' },
  { accessorKey: 'controllingEndDate', minSize: 120, header: 'CONTROLLING END DATE' },
]

interface CombinedCoverageHistoryProps {
  currentComboId: string
  data: CombinedCoverageHistoryModel[] | undefined
  isLoading: boolean
}

const CombinedCoverageHistory = ({ currentComboId, data, isLoading }: CombinedCoverageHistoryProps) => {
  const { comboId } = useParams()
  const [sourceCoverage, setSourceCoverage] = useState<SourceCoverageData>()
  const {
    value: isMoveCoverageModalOpen,
    onFalse: closeMoveCoverageModal,
    onTrue: openMoveCoverageModal,
  } = useBoolean(false)

  const kebabMenuOptions: PhoenixBaseTableProps<CombinedCoverageHistoryModel>['kebabMenuOptions'] = [
    {
      label: 'Modify Details',
      // TODO: Finish this in future ticket
      to: () => ``,
      disabled: () => true,
    },
    {
      label: 'Move Coverage',
      to: () => ``,
      onClick: ({ row }) => {
        setSourceCoverage({
          coverageId: row.original.coverageId,
          comboId: currentComboId,
          comboGuid: comboId!,
          primaryName: row.original.primaryName,
          includeDate: row.original.includeDate,
        })
        openMoveCoverageModal()
      },
      disabled: () => data?.length === 1,
    },
    {
      label: 'Set Controlling Coverage',
      // TODO: Finish this in future ticket
      to: () => ``,
      disabled: () => true,
    },
  ]

  return (
    <>
      <PhoenixBaseCard cardTitle={'Combined Coverage History'}>
        <PhoenixBaseTable
          columns={columns}
          data={data ?? []}
          isLoading={isLoading}
          kebabMenuOptions={kebabMenuOptions}
        />
      </PhoenixBaseCard>
      <MoveCoverageModal
        isOpen={isMoveCoverageModalOpen}
        onClose={closeMoveCoverageModal}
        sourceCoverage={sourceCoverage}
      />
    </>
  )
}

export default CombinedCoverageHistory
