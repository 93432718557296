import { AccessTime, DateRange, Inbox } from '@mui/icons-material'
import { Paper, Skeleton, Stack, Typography } from '@mui/material'
import { useGetWorkQueueDetails } from '../../cache/workQueueCache'

export const MyWorkQueue = () => {
  const { data: MyQueueItems, isPending: isWorkQueueDetailsLoading } = useGetWorkQueueDetails()

  const getCurrentDate = () => {
    return new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
  }

  return (
    <Paper elevation={0}>
      <Stack spacing={1} padding={1}>
        <Typography variant="body2">{getCurrentDate()}</Typography>
        {[
          {
            icon: <Inbox style={{ fontSize: 30, color: '#6e6e6e' }} />,
            label: 'ASSIGNED TO ME',
            value: MyQueueItems?.assigned,
          },
          {
            icon: <AccessTime style={{ fontSize: 30, color: '#6e6e6e' }} />,
            label: 'DUE TODAY',
            value: MyQueueItems?.due,
          },
          {
            icon: <DateRange style={{ fontSize: 30, color: '#6e6e6e' }} />,
            label: 'OVERDUE',
            value: MyQueueItems?.overdue,
          },
        ].map((item, index) => (
          <Paper
            key={index}
            elevation={0}
            square={false}
            style={{ paddingTop: '10px', paddingLeft: '10px', paddingBottom: '20px', backgroundColor: '#fafafa' }}
          >
            <Stack direction="row" spacing={2}>
              {item.icon}
              <Stack>
                <Typography variant="overline" sx={{ lineHeight: '1.4rem' }}>
                  {item.label}
                </Typography>
                {isWorkQueueDetailsLoading ? (
                  <Skeleton height={29.5} variant={'rounded'} width={40} />
                ) : (
                  <Typography variant="h4" sx={{ fontSize: '1.5rem' }}>
                    {item.value ?? 0}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Paper>
        ))}
      </Stack>
    </Paper>
  )
}
export default MyWorkQueue
