import { Box, Typography } from '@mui/material'
import React from 'react'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import MyWorkQueue from '../components/WorkQueue/MyWorkQueue'
import WorkQueueTabs from '../components/WorkQueue/WorkQueueTabs'
import { useNavbar } from '../contexts/NavbarContext'

export const WorkQueue = () => {
  const { pageStyle } = useNavbar()

  return (
    <div id="work-queue" style={pageStyle}>
      <Box sx={{ background: '#d8e1ed', padding: 1.5, borderRadius: '8px 8px 0px 0px' }}>
        <Typography variant={'body1'} fontSize={14} fontWeight={700}>
          My Work Queue
        </Typography>
      </Box>
      <LayoutWithSideRail
        mainContent={<WorkQueueTabs />}
        sideRailComponentArray={[<MyWorkQueue key="MyWorkQueue" />]}
      />
    </div>
  )
}
