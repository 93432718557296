import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { AlertTitle, Box, Tab, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import { PhoenixBaseCard, PhoenixBaseTable, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React from 'react'
import { useTabs } from '../../../hooks/useTabs'
import { AffectedRating, RatingsImpactedResponse } from '../../../models/CoverageMovement'

const columns: MRT_ColumnDef<AffectedRating>[] = [
  { accessorKey: 'ratingEffectiveDate', header: 'Rating Effective Date' },
  {
    accessorKey: 'modFactor',
    header: 'Mod Factor',
    // eslint-disable-next-line react/prop-types
    Cell: ({ row }) => (
      // eslint-disable-next-line react/prop-types
      <PhoenixLink to={`/ratings/${row.original.ratingGuid}`} value={row.original.modFactor} openInNewTab />
    ),
  },
  { accessorKey: 'statusDate', header: 'Status Date' },
]

interface RatingsImpactedSectionProps {
  originComboGuid: string | undefined
  destinationComboGuid: number | undefined
  ratingsImpacted: RatingsImpactedResponse
}

const RatingsImpactedSection = ({
  originComboGuid,
  destinationComboGuid,
  ratingsImpacted,
}: RatingsImpactedSectionProps) => {
  const { tabIndex, handleTabChange } = useTabs()
  // Hide destination tab and table the destination is a new combo
  const destinationComboExists = !!destinationComboGuid

  return (
    <PhoenixBaseCard cardTitle="Ratings Impacted" variantType="Secondary" defaultWidth={12}>
      <TabContext value={tabIndex}>
        <TabList onChange={handleTabChange}>
          <Tab label={`COMBO ${originComboGuid}`} value="0" />
          {destinationComboExists && <Tab label={`COMBO ${destinationComboGuid}`} value="1" />}
        </TabList>
        <TabPanel value="0" sx={{ padding: 0, width: '100%' }}>
          <Box>
            <PhoenixBaseTable columns={columns} data={ratingsImpacted?.origin} />
          </Box>
        </TabPanel>
        {destinationComboExists && (
          <TabPanel value="1" sx={{ padding: 0, width: '100%' }}>
            <PhoenixBaseTable columns={columns} data={ratingsImpacted?.destination} />
          </TabPanel>
        )}
      </TabContext>
      <Alert severity={'info'} sx={{ width: '100%', mt: 2 }}>
        <AlertTitle>Combinability changes will impact ratings.</AlertTitle>
        <Typography variant="body2">Click CONFIRM CHANGES to apply.</Typography>
      </Alert>
    </PhoenixBaseCard>
  )
}

export default RatingsImpactedSection
