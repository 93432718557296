import { Box, Button } from '@mui/material'
import { DataFieldBlankValue, PhoenixBaseCard, PhoenixBaseTable, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { useState } from 'react'
import { useGetSuggestedMatches } from '../../cache/workQueueCache'
import { useMatchPolicyToCoverage } from '../../hooks/workQueue/useMatchPolicyToCoverage'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { SuggestedMatchModel } from '../../models/WorkItemDetails/SuggestedMatchModel'
import { UnmatchedTransactionModel } from '../../models/WorkItemDetails/UnmatchedTransactionModel'
import { handleTitleCase } from '../../utils/handleTitleCase'
import SearchByEmployerNameModal from '../Modals/SearchByEmployerNameModal'
import MatchPolicyToCoverageModal from './MatchPolicyToCoverageModal'

/* eslint react/prop-types: 0 */
const SuggestedMatchesColumns: MRT_ColumnDef<SuggestedMatchModel>[] = [
  {
    accessorKey: 'combo.identifier',
    header: 'Combo ID',
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    Cell: ({ cell, row }) => (
      <>
        {row.original.combo.guid ? (
          <PhoenixLink
            id={`Combo-${row.index}`}
            to={`/combos/${row.original.combo.guid}`}
            value={cell.getValue<string>()}
          />
        ) : (
          <DataFieldBlankValue />
        )}
      </>
    ),
  },
  {
    accessorKey: 'coverage.identifier',
    header: 'Coverage ID',
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    Cell: ({ cell, row }) => (
      <>
        {cell.getValue() ? (
          <PhoenixLink
            id={`Coverage-${row.index}`}
            to={`/coverages/${row.original.coverage.identifier}`}
            value={cell.getValue<string>()}
          />
        ) : (
          <DataFieldBlankValue />
        )}
      </>
    ),
  },
  {
    accessorKey: 'employer',
    header: 'Employer',
    Cell: ({ cell }: any) => {
      return handleTitleCase(cell.getValue())
    },
  },
  {
    accessorKey: 'address',
    header: 'Address',
    Cell: ({ cell }: any) => {
      return handleTitleCase(cell.getValue())
    },
  },
  {
    accessorKey: 'fein',
    header: 'FEIN',
  },
]

interface SuggestedMatchesProps {
  unmatchedTransaction: UnmatchedTransactionModel | undefined
  uuid: string
  workItemStatus: string | undefined
}

const SuggestedMatches = ({ uuid, unmatchedTransaction, workItemStatus }: SuggestedMatchesProps) => {
  const [openSearchByEmployerNameModal, setOpenSearchByEmployerNameModal] = useState(false)
  const {
    isModalOpen,
    openModal,
    closeModal,
    match,
    successMatch,
    activeStep,
    handleConfirmMatch,
    isMatchLoading,
    setFoundMatch,
  } = useMatchPolicyToCoverage()

  const isWorkItemStatusOpen = workItemStatus === 'Open'

  const {
    data: suggestedMatches,
    isPending: isSuggestedMatchesLoading,
    isError: isSuggestMatchesError,
  } = useGetSuggestedMatches(uuid)

  useSetAlertDetails([isSuggestMatchesError])

  return (
    <>
      <PhoenixBaseCard
        cardTitle="Suggested Matches"
        isLoading={isSuggestedMatchesLoading}
        kebabable={isWorkItemStatusOpen}
        kebabMenuItems={[
          {
            label: 'Search by Employer Name',
            to: '',
            onClick: () => setOpenSearchByEmployerNameModal(true),
          },
        ]}
      >
        <PhoenixBaseTable
          columns={SuggestedMatchesColumns}
          data={suggestedMatches ?? []}
          overrides={{
            enableRowActions: true,
            positionActionsColumn: 'last',
            // eslint-disable-next-line
            // @ts-ignore
            renderRowActions: ({ row }) =>
              isWorkItemStatusOpen ? (
                <Box display={'flex'} justifyContent={'flex-end'} pr={'4px'}>
                  <Button variant="contained" color="primary" size="small" onClick={() => openModal(row.original)}>
                    Match
                  </Button>
                </Box>
              ) : (
                <></>
              ),
          }}
        />
      </PhoenixBaseCard>
      <MatchPolicyToCoverageModal
        data={unmatchedTransaction}
        isOpen={isModalOpen}
        handleClose={closeModal}
        setFoundMatch={setFoundMatch}
        match={match}
        successMatch={successMatch}
        isMatchLoading={isMatchLoading}
        activeStep={activeStep}
        handleConfirmMatch={handleConfirmMatch}
      />
      <SearchByEmployerNameModal
        isOpen={openSearchByEmployerNameModal && !isModalOpen}
        onCancel={() => setOpenSearchByEmployerNameModal(false)}
        onMatchClick={openModal}
      />
    </>
  )
}

export default SuggestedMatches
