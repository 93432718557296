import { ControllingCoverageItem } from '../../models/ControllingCoverageOptions'
import { handleTitleCase } from '../handleTitleCase'

export const generateControllingCoverageLabel = (options: ControllingCoverageItem[], currentOption: ControllingCoverageItem | undefined) => {
  const coverageString = `${currentOption?.coverageId} - ${handleTitleCase(currentOption?.coverageName)}`

  if (currentOption?.isSuggestedChoice) {
    if (options.length === 1) {
      return `${coverageString} (Automatic)`
    }
    return `${coverageString} (Suggested)`
  }
  return coverageString
}
