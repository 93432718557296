import { BreadcrumbBar, PhoenixBaseCard } from 'componix'
import { UUID } from 'crypto'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetAncestors } from '../cache/ancestorCache'
import { useGetPolicySummaryDetails } from '../cache/policyCache'
import { ExperienceRating } from '../components/ExperienceRating'
import Exposure from '../components/Exposure'
import PrimaryCoverageIdentifier from '../components/GroupIdentifier'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import PolicyCancellationsReinstatements from '../components/PolicyCancellationsReinstatements'
import { PolicyDetailsContent } from '../components/PolicyDetails'
import PolicyErrorStatusBanner from '../components/PolicyDetails/PolicyErrorStatusBanner'
import PolicyEmployersLocations from '../components/PolicyEmployersLocations'
import { PolicyEndorsementsContent } from '../components/PolicyEndorsementsContent'
import PolicyErrors from '../components/PolicyErrors'
import { PolicyPremiumsContent } from '../components/PolicyPremiumsContent'
import RelatedCoverageIDsCard from '../components/RelatedCoverageIDs'
import UnitStatReportsTable from '../components/UnitStatReports/UnitStatReportsTable'
import { useNavbar } from '../contexts/NavbarContext'
import { HierarchyTypes } from '../utils/hierarchy.enum'

const PolicyDetail: React.FC = () => {
  const { policyId } = useParams()
  const { pageStyle } = useNavbar()

  const { data: ancestorData } = useGetAncestors(policyId as UUID, HierarchyTypes.POLICY)

  const {
    data: policySummaryData,
    isPending: isPolicySummaryLoading,
    isError: isPolicySummaryError,
  } = useGetPolicySummaryDetails(policyId as UUID)

  //Check if data is already loaded when accordion is expanded

  const policyDetailsKebabMenuItems = [{ label: 'View Unit Stat Reports', to: `/policies/${policyId}/unitstats` }]
  return (
    <div style={pageStyle}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${ancestorData?.combo?.identifier}`,
            to: `/combos/${ancestorData?.combo?.guid}`,
          },
          {
            label: `Coverage ${ancestorData?.coverage?.identifier}`,
            to: `/coverages/${ancestorData?.coverage?.identifier}`,
          },
          {
            label: `Policy ${ancestorData?.policy?.identifier}`,
            to: `/policies/${policyId}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <div>
            <PolicyErrorStatusBanner hasOutstandingErrors={policySummaryData?.summary?.outstandingErrors} />
            <PhoenixBaseCard cardTitle={'Policy Details'} contentPadded kebabMenuItems={policyDetailsKebabMenuItems}>
              <PolicyDetailsContent
                policySummaryData={policySummaryData}
                isPolicySummaryLoading={isPolicySummaryLoading}
                isPolicySummaryError={isPolicySummaryError}
              />
            </PhoenixBaseCard>
            <PolicyPremiumsContent uuid={policyId as string} />
            <Exposure uuid={policyId as string} />
            <PolicyEmployersLocations uuid={policyId as string} />
            <PolicyCancellationsReinstatements uuid={policyId as string} />
            <PolicyEndorsementsContent uuid={policyId as string} />
            <UnitStatReportsTable policyID={policyId as string} />
            <PolicyErrors policyID={policyId as UUID} />
          </div>
        }
        sideRailComponentArray={[
          <PrimaryCoverageIdentifier key={'cgh'} combo={ancestorData?.combo} coverage={ancestorData?.coverage} />,
          <ExperienceRating combo={ancestorData?.combo} key="er" />,
          <RelatedCoverageIDsCard
            comboId={ancestorData?.combo?.guid}
            coverageId={ancestorData?.coverage?.guid}
            key="rcic"
          />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}

export default PolicyDetail
