import { useEffect } from 'react'
import { AlertSeverity } from '../../components/AlertNotification/AlertNotification'
import { useAlertContext } from '../../contexts/AlertContext'

const useSetAlertDetails = (errors: boolean[], message?: string, severity?: AlertSeverity) => {
  const { setDetails } = useAlertContext()

  useEffect(() => {
    if (errors.includes(true)) {
      setDetails({
        message: message ?? 'Please refresh the page and try again.',
        severity: severity ?? AlertSeverity.ERROR,
      })
    }
    // eslint-disable-next-line
  }, [...errors, message, setDetails])
}

export default useSetAlertDetails
