import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import React, { ReactNode, createContext, useContext, useState } from 'react'

interface NavbarContextProps {
  open: boolean
  handleOpen: () => void
  pageStyle: {
    transition: string
    marginLeft: string
    marginTop: string
    marginRight: string
  }
}

const NavbarContext = createContext<NavbarContextProps | undefined>(undefined)

interface NavbarProviderProps {
  children: ReactNode
}

const NavbarProvider: React.FC<NavbarProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const marginLeft = open ? '117px' : '24px'
  const pageStyle = {
    transition: 'margin-left 0.2s',
    marginLeft: marginLeft,
    marginTop: '80px',
    marginRight: '24px',
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <NavbarContext.Provider value={{ open, handleOpen, pageStyle }}>{children}</NavbarContext.Provider>
    </MsalAuthenticationTemplate>
  )
}

const useNavbar = (): NavbarContextProps => {
  const context = useContext(NavbarContext)

  if (!context) {
    throw new Error('useNavbar must be used within a NavbarProvider')
  }

  return context
}

export { NavbarProvider, useNavbar }
